import React, { Suspense, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

// Home
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
// Blog
const Blog = React.lazy(() => import("./components/pages/Blog"));

const Articulo = React.lazy(() => import("./components/pages/Articulo"));
// About
const About = React.lazy(() => import("./components/pages/About"));
const Ayuda = React.lazy(() => import("./components/pages/Ayuda"));
const AvisoLegal = React.lazy(() => import("./components/pages/AvisoLegal"));
const PoliticasPrivacidad = React.lazy(() => import("./components/pages/PoliticasPrivacidad"));
// Services

// FAQ's
const Faqs = React.lazy(() => import("./components/pages/Faqs"));
// Appointment

// Clinics

const Cliniclist = React.lazy(() => import("./components/pages/Cliniclist"));
const Clinicdetails = React.lazy(() => import("./components/pages/Clinicdetails"));


// Contact
const Contact = React.lazy(() => import("./components/pages/Contact"));
// Extra
const Errorpage = React.lazy(() => import("./components/pages/Errorpage"));

// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})


function App() {
  return (
    <Router basename={"/"}>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Switch>
            {/* Home */}
            <Route exact path="/" component={Blog} />
            {/* Blog */}
            <Route exact path="/buscador" component={Hometwo} />
            <Route exact path="/blog" component={() => <Redirect to={'/'} />} />
            <Route exact path="/articulo/:id" component={props => (<Articulo {...props} key={window.location.pathname} />)} />
            {/* About */}
            <Route exact path="/about" component={About} />
            <Route exact path="/ayuda" component={Ayuda} />
            <Route exact path="/aviso-legal" component={AvisoLegal} />
            <Route exact path="/politicas-privacidad" component={PoliticasPrivacidad} />
            {/* FAQ's */}
            <Route exact path="/faqs" component={Faqs} />
            {/* Cursos*/}
            <Route exact path="/cursos" component={Cliniclist} />
            <Route exact path="/cursos/search/:query" component={props => <Cliniclist {...props} key={window.location.pathname} />} />
            <Route exact path="/curso/:id" component={props => (<Clinicdetails {...props} key={window.location.pathname} />)} />
            {/* Contact */}
            <Route exact path="/contact" component={Contact} />
            {/* Extra */}
            <Route exact component={Errorpage} />
          </Switch>
        </ScrollToTop>
      </Suspense>
      <CookieConsent buttonText='Aceptar' location='top' style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>Para brindar las mejores experiencias, utilizamos tecnologías como cookies para almacenar y/o acceder a información en el dispositivo. El consentimiento para estas tecnologías nos permitirá procesar datos como el comportamiento de navegación o identificadores únicos en este sitio web. No consentir puede afectar negativamente a ciertas características y funciones. <a href='/politicas-privacidad'>Políticas de Privacidad</a></CookieConsent>
    </Router>
  );
}

export default App;
